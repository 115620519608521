.popup--order {
  .popup__nav {
    display: flex;
    align-items: stretch;
    justify-content: space-around;

    .nav__item {
      width: 50%;
      padding: .7em 1em;
      text-align: center;
      font-weight: var(--font-weight-heavy);
      background-color: var(--main-color);
      color: white;

      span {
        width: 100%;
        height: 100%;
        cursor: default;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.--left {
        border-bottom-right-radius: var(--radius-twenty);
      }

      &.--right {
        border-bottom-left-radius: var(--radius-twenty);
      }

      &.--selected {
        color: var(--theme-text);
        background: none;
      }
    }
  }

  .loading {
    margin: 2em 0;
  }

  .popup__error {
    padding: 1em;
    text-align: center;
    color: var(--theme-text);
  }

  .popup__main {
    margin: 0 2.5em 1em;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .popup__title {
      margin: 0.8em 0;
      color: var(--theme-text);
      font-size: var(--font-size-thirty);
      font-weight: var(--font-weight-heavy);
    }

    .popup__form {
      width: 100%;
    }

    p {
      margin-block-end: 0.6em;
      color: var(--theme-text);
      font-size: var(--font-size-fourteen);
      font-weight: var(--font-weight-semi-bold);
    }

    .popup__buttons {
      margin-top: 1em;
      width: 100%;
    }

    .range {
      margin: 1em 0;
      padding-top: 58px;
      position: relative;

      .range__line {
        width: 100%;
        height: 2px;
        position: relative;
        background-color: var(--border-secondary-color);

        .range__peroid {
          position: absolute;
          top: -3px;
          width: 100%;
          display: flex;

          .peroid__item {
            height: 8px;
            border-right: 2px solid var(--border-secondary-color);

            &:last-child {
              visibility: hidden;
            }
          }
        }

        .range__selected {
          position: absolute;
          height: 2px;
          background-color: var(--main-color);
          z-index: 1;

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: white;
            top: -2px;
            width: 4px;
            height: 4px;
            border: 1px solid var(--main-color);
            border-radius: 50%;
          }

          &::before {
            left: -2px;
          }

          &::after {
            right: -2px;
          }
        }
      }

      .range__selector {
        position: absolute;
        user-select: none;
        background-color: var(--theme-secondary-bg);
        top: -2px;

        .selector__desc {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);

          div {
            color: var(--theme-text);
            font-weight: var(--font-weight-heavy);

            &:nth-child(2) {
              font-size: 0.75em;
            }
          }
        }
      }
    }
  }
}
