.foodCounter {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 12vmin;
    height: 12vmin;
    max-height: 50px;
    max-width: 50px;
    font-size: 1.5em;
    font-weight: normal;

    &.button__text.--border {
      margin: 0;
    }
  }

  input.counter__input {
    padding: 0 0.5em;
    width: 40px;
    height: 12vmin;
    max-height: 50px;
    background: none;
    text-align: center;
    font-family: inherit;
    border: 1px solid var(--border-secondary-color);
    border-radius: var(--default-radius);
  }

  
  .counter__title {
    padding: 0.5em;
  }
  
  .counter__title,
  .counter__input {
    margin: 0 0.5em;
    color: var(--theme-text);
    font-weight: var(--font-weight-heavy);
    font-size: var(--font-size-twenty-two);
  }
}
