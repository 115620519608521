.popup__main--onebtn {
  text-align: center;
  padding: 5% 10%;

  .popup__title {
    color: var(--theme-text);
    font-weight: var(--font-weight-heavy);
  }

  .popup__description {
    color: var(--theme-secondary-text);
    margin-bottom: 10%;
  }
}
