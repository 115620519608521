.google-pay-button-container {
  width: 100%;
  height: 100%;

  div {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
      appearance: none;
      border-radius: var(--default-radius);
    }
  }
}