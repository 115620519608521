.reservation {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .reservation__main {
    margin: 5vmin;
    padding: 5vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    h2 {
      color: var(--theme-text);
    }

    p {
      text-align: center;
      color: var(--theme-secondary-text);
    }

    .form__inputs,
    .reservation__buttons,
    .reservation__options--free {
      width: 100%;
      padding: 0.5em 5vmin 0;
      box-sizing: border-box;
    }

    .reservation__options--paid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .options__item {
        margin: 1vmin;
        width: 36vmin;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: 1px solid var(--main-color);
        border-radius: var(--default-radius);

        &.--selected {
          background-color: rgba(#d2ad56, 0.3);
        }

        & > * {
          padding: 2vmin 2vmin 0;
        }

        img {
          width: 34vmin;
        }

        .options__title {
          color: var(--theme-text);
          font-weight: bold;
        }

        .options__once {
          padding: 5px;
          color: var(--theme-text);
          font-weight: var(--font-weight-semi-bold);
          font-size: var(--font-size-twelve);
        }

        .options__desc {
          font-size: var(--font-size-twelve);
          color: var(--theme-secondary-text);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .options__buttons {
          width: 100%;
          box-sizing: border-box;

          .button__price {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }

    .reservation__options--free .options__item {
      margin: 0.5em;
      padding: 1em;
      border: 1px solid var(--main-color);
      border-radius: var(--default-radius);

      &.--selected {
        background-color: var(--main-color);

        .options__title {
          color: white;
        }
      }

      .options__title {
        color: var(--theme-text);
        text-align: center;
        font-weight: bold;
        font-size: var(--font-size-twelve);
      }
    }

    .reservation__summary {
      text-align: center;

      & > div {
        margin: 1em 0;
      }

      .summary__title {
        margin-bottom: 5px;
        color: var(--theme-text);
        font-size: var(--font-size-fourteen);
        font-weight: bold;
      }

      .summary__text {
        color: var(--theme-secondary-text);
      }

      .summary__group {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 5px;
        }
      }

      hr {
        width: 70%;
        border: none;
        border-top: 1px solid var(--border-secondary-color);
      }
    }
  }
}
