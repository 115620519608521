$twelve: var(--font-size-twelve);

.list__element--tight {
  height: 20vmax;
  margin: 2vmax 0;
  display: flex;
  align-items: center;

  &.--inactive {
    opacity: 0.5;
  }

  &.--nophoto .element__col {
    & > * {
      margin: 1vmax 2vmax;
    }
  }

  img {
    width: 70vmax;
    max-width: 30vh;
    height: 90%;
    max-height: 35vh;
    overflow: hidden;
    border-radius: var(--radius-twenty);
  }

  .element__photo {
    width: 70vmax;
    max-width: 30vh;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-twenty);
    border: 1px solid var(--border-secondary-color);

    .photo__desc {
      color: var(--theme-text);
      font-weight: var(--font-weight-semi-bold);
      font-size: var(--font-size-eleven);
    }
  }

  .element__col {
    margin: 1vmax;

    & > * {
      margin: 1vmax;
    }

    .element__title {
      font-weight: var(--font-weight-heavy);
      color: var(--theme-text);
    }

    .element__row {
      display: flex;

      button {
        margin-right: 1vmax;
      }

      .element__group {
        margin-right: 2vmax;
        display: flex;

        .element__title {
          margin-left: 1vmax;
          font-size: $twelve;
          font-weight: normal;
        }
      }
    }
    .element__desc {
      margin-bottom: 2vmax;
      color: var(--theme-secondary-text);
      font-size: $twelve;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 900px) {
  .list__element--tight {
    width: 50%;
    height: 20vh;
    margin: 2vh 0;

    img {
      height: 22vh;
    }
  }
}
