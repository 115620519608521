.select {
  width: 100%;
  margin: 1vmin 0;
  position: relative;

  .select__icon {
    position: absolute;
    top: 10px;

    &.--left {
      left: 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &.--right {
      right: 10px;

      .arrow__down {
        content: '';
        width: 6px;
        height: 6px;
        top: 5px;
        right: 2px;
        position: absolute;
        border: solid var(--main-color);
        border-width: 0px 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  select.select__main {
    width: 100%;
    padding: 10px;
    text-align: center;
    text-align-last: center;
    text-align-last: -webkit-center;
    font-size: 1em;
    font-family: inherit;
    appearance: none;
    color: var(--theme-text);
    background-color: var(--theme-secondary-bg);
    font-weight: var(--font-weight-semi-bold);
    border: 1px solid var(--border-secondary-color);
    border-radius: var(--default-radius);

    &.--warning {
      border-bottom: 1px solid var(--red-color);
    }

    &:required:invalid {
      color: grey;
    }

    option {
      color: var(--theme-text);
      text-align: center;
      text-align-last: center;
    }
  }

  span.select__warning {
    color: var(--red-color);
    font-size: var(--font-size-twelve);
  }
}
