.popup__main--about {
  color: var(--theme-text);
  text-align: center;
  padding: 10%;

  & > * {
    margin: 1em;
  }

  .about__title {
    margin-top: 2em;
    font-weight: var(--font-weight-heavy);
  }

  .about__date {
    color: var(--theme-secondary-text);
    font-size: var(--font-size-fourteen);
  }

  hr {
    width: 70%;
    border: none;
    border-top: 1px solid var(--border-secondary-color);
  }

  .about__address {
    font-size: var(--font-size-eighteen);
    font-weight: bold;
  }

  .about__contact a {
    padding: 2px;
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: var(--font-weight-semi-bold);
    text-decoration: none;
    display: block;
  }

  .about__buttons {
    margin: 0;
    margin-top: 2em;
    display: flex;

    & > * {
      margin: 0 5px;
    }
  }
}
