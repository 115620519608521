.settings__list {
  padding: 0 5vmin;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  .list__column .list__row {
    display: flex;

    .list__element {
      display: flex;
      flex-direction: column;
      margin: 1.5vh;

      .list__title {
        margin: 0.5vh;
        align-self: flex-start;
        cursor: pointer;
        font-size: var(--font-size-twelve);
        font-weight: var(--font-weight-semi-bold);
        color: var(--theme-text);
        opacity: 1;

        &.--dim {
          opacity: 0.5;
        }
      }
    }
  }

  .list__column:nth-child(2) {
    margin-top: -20px;
    flex-shrink: 0;

    .list__row {
      justify-content: flex-end;

      .list__element {
        margin: 0.5vh;

        .list__title {
          font-weight: var(--font-weight-heavy);
          font-size: 1em;
          margin: 0;
        }
      }
    }
  }
}
