.button__iconText {
  width: fit-content;
  min-height: 34px;
  height: 2.6em;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: inherit;
  background-color: var(--theme-bg);
  border-radius: var(--default-radius);
  border: 1px solid var(--border-secondary-color);

  &.--filled {
    border: none;
    background-color: var(--main-color);

    svg > *,
    svg g > * {
      fill: white;
    }

    .button__description {
      color: white;
    }
  }

  &.--enlarged {
    height: 2.8em;

    svg {
      max-width: 2em;
    }

    .button__description {
      font-weight: var(--font-weight-medium);
    }
  }

  &.--column {
    padding: 0.5em;
    height: auto;
    flex-direction: column;

    .button__description {
      padding: 0 .75rem;
    }
  }

  svg {
    padding: 6px;
    max-width: 2em;
  }

  .button__description {
    padding: 10px 6px;
    color: var(--theme-secondary-text);
    font-size: var(--font-size-twelve);
    font-weight: bold;
    white-space: nowrap;
  }
}
