.popup--compose {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: var(--toast-bg);

  .popup__interior {
    position: fixed;
    bottom: 0;
    width: 100%;

    & > :first-child {
      margin-bottom: 2vmax;
    }

    .popup__conteiner {
      background-color: var(--theme-bg);

      .popup__main {
        max-height: calc(var(--vh, 1vh) * 70);
        overflow: auto;

        .popup__title {
          padding: 1em;
          position: sticky;
          top: 0;
          text-align: center;
          color: var(--theme-text);
          background-color: var(--theme-bg);
          font-size: var(--font-size-twenty-two);
          font-weight: var(--font-weight-heavy);
        }

        .popup__list {
          margin: 0.5em 1em;
          border-radius: var(--radius-twenty);
          background-color: var(--theme-secondary-bg);
          box-shadow: 0 0 1em var(--shadow-color-tiles);

          .list__title {
            padding-top: 1em;
            text-align: center;
            color: var(--theme-text);
            font-size: var(--font-size-fourteen);
            font-weight: var(--font-weight-semi-bold);
          }

          .list__main {
            padding: 1vmin;
          }
        }
      }

      .popup__footer {
        min-height: 100px;
        height: calc(var(--vh, 1vh) * 20);
        padding: 0 5vmin;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--theme-secondary-bg);
        box-shadow: 0 -1em 1em var(--shadow-color-tiles);

        .footer__col {
          .footer__title {
            margin-bottom: 1vh;
            color: var(--theme-text);
            font-size: var(--font-size-twelve);
            font-weight: var(--font-weight-semi-bold);
          }

          .footer__group {
            height: 12vmin;
            max-height: 50px;
            display: flex;
            align-items: center;

            .footer__price,
            .footer__button {
              padding: 0 10px;
            }

            .footer__price {
              height: 100%;
              display: flex;
              align-items: center;
              color: var(--theme-text);
              font-size: var(--font-size-fourteen);
              border: 1px solid var(--border-secondary-color);
              border-top-left-radius: var(--default-radius);
              border-bottom-left-radius: var(--default-radius);
              box-sizing: border-box;

              span {
                font-weight: var(--font-weight-heavy);
              }
            }

            .footer__button {
              height: 100%;
              display: flex;
              align-items: center;
              color: white;
              background-color: var(--main-color);
              font-size: var(--font-size-twelve);
              font-weight: var(--font-weight-medium);
              border-top-right-radius: var(--default-radius);
              border-bottom-right-radius: var(--default-radius);

              span {
                margin-left: 5px;
              }

              svg g > * {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}
