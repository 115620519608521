.banners {
  text-align: center;

  .banners__header {
    margin: 1em 0;

    .banners__title {
      margin: 1em;
      color: var(--theme-text);
      font-weight: bold;
    }
  }

  .banners__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .banner {
      margin: 1em;
      width: calc(100vw - 10vmin);
      max-width: 600px;

      .banner__picture {
        min-height: 100px;
        position: relative;
        z-index: 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 40%;
          opacity: 0.5;
          border-top-left-radius: var(--default-radius);
          border-top-right-radius: var(--default-radius);
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 1;
        }

        img {
          width: 100%;
          border-top-left-radius: var(--default-radius);
          border-top-right-radius: var(--default-radius);
        }

        .banner__title {
          position: absolute;
          top: 2em;
          left: 50%;
          color: white;
          font-size: var(--font-size-eighteen);
          font-weight: var(--font-weight-heavy);
          transform: translateX(-50%);
          z-index: 1;
        }
      }

      .banner__details {
        margin-top: -2px;
        border: solid var(--main-color);
        border-width: 0 1px 1px;
        border-bottom-left-radius: var(--default-radius);
        border-bottom-right-radius: var(--default-radius);

        .banner__desc {
          padding: 2em 2em 1em;
          color: var(--theme-text);
          font-size: var(--font-size-twelve);
          font-weight: var(--font-weight-semi-bold);
        }

        .banner__button {
          margin: 1em 15% 2em;
        }
      }
    }
  }
}
