.theme__switcher {
  &:active {
    animation: rotation 1s ease-in-out forwards;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
