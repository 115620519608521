.categories__tile {
  margin: 3vmin 2vmin;
  width: 44vmin;
  height: 44vmin;
  max-width: 200px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--theme-secondary-bg);
  border-radius: var(--radius-twenty);
  box-shadow: 0 0 1em var(--shadow-color-tiles);
  text-decoration: none;

  svg:nth-child(3) {
    max-width: 30vmin;
  }

  .tile__title {
    margin-top: 5px;
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: bold;
  }

  .tile__desc {
    margin-top: 10px;
    text-align: center;
    white-space: break-spaces;
    color: var(--theme-secondary-text);
    font-size: var(--font-size-twelve);
    font-weight: var(--font-weight-medium);
  }
}

.categories__tile--desktop {
  margin: 2vh 4vh;
  color: var(--theme-text);
  text-decoration: none;
  font-weight: var(--font-weight-medium);

  &.--dim {
    color: var(--main-color);
    text-decoration: underline;
  }
}
