.popup__main--foodDetails {
  .popup__top {
    margin: 1em;
    display: flex;
    justify-content: center;
    color: var(--theme-text);
    font-weight: var(--font-weight-heavy);
  }

  .popup__header {
     width: 100%;
     text-align: center;

    svg {
      width: 6vmax;
      height: 6vmax;
      min-width: 60px;
      min-height: 60px;
      max-width: 100px;
      max-height: 100px;
    }
    
    .popup__title {
      margin: 1em;
      color: var(--theme-text);
      font-size: var(--font-size-twenty-two);
      font-weight: var(--font-weight-heavy);
    }

    hr {
      width: 30%;
      border: none;
      border-top: 1px solid var(--border-secondary-color);
    }
    
    img {
      width: 100%;
      height: 100%;
      max-height: 200px;
      object-fit: cover;
      border-top-left-radius: var(--radius-twenty);
      border-top-right-radius: var(--radius-twenty);
    }

  @media screen and (min-width: 600px) {
      img {
        max-height: 400px;
      }
    }
  }

  .popup__description {
    margin: 1em 2.5em;
    text-align: center;
    color: var(--theme-secondary-text);
  }

  .popup__buttons {
    margin: 2em;
    display: flex;
    justify-content: center;

    .button__share {
      min-height: 34px;
      height: 2.6em;
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--default-radius);
      border: 1px solid var(--main-color);
      box-sizing: border-box;
    }

    & > button {
      margin: 0 5px;
    }
  }
}
