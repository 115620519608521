button.button__icontext--wide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  border-radius: var(--default-radius);
  background-color: var(--main-color);
  border: none;

  & > * {
    padding: 8px;
  }

  .button__description {
    font-weight: var(--font-weight-heavy);
    color: white;
  }
}
