.chat {
  main {
    position: fixed;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;

    nav {
      padding: 1em 1em 0;
      width: 100%;
      display: flex;
      align-items: center;
      overflow-x: auto;
      box-sizing: border-box;

      .button__back {
        min-height: 48px;
      }

      .chat__button {
        padding: 8px;
        min-width: 40px;
        flex-shrink: 0;
        text-align: center;
        color: var(--theme-secondary-text);
        border: 1px solid var(--border-secondary-color);
        border-radius: var(--default-radius);

        div {
          font-weight: bold;
          font-size: var(--font-size-twelve);
        }

        span {
          font-size: var(--font-size-eleven);
        }

        &.--filled {
          background-color: var(--main-color);
          border-color: var(--main-color);
          color: white;
        }
      }

      & > * {
        margin: 0 2px;
      }
    }

    .chat__receiver {
      height: calc(100% - 134px);
      overflow-y: auto;

      .receiver__title {
        text-align: center;
        color: var(--theme-text);
        margin-block-end: 0.83em;
        margin-block-start: 0.83em;
        font-weight: var(--font-weight-semi-bold);

        .--big {
          font-size: 1.1em;
        }

        .--small {
          margin-block-start: 0.25em;
          font-size: var(--font-size-twelve);
        }
      }

      .receiver__response {
        margin: 0.5em;
        display: flex;
        flex-direction: column;
        align-items: center;

        .receiver__message {
          display: flex;
          align-items: flex-end;
          max-width: 85%;

          &.--user {
            align-self: flex-end;

            .message__main {
              color: white;
              background-color: var(--main-color);

              .message__time {
                color: white;
              }
            }
          }

          &.--restaurant {
            align-self: flex-start;
            flex-direction: row-reverse;

            .message__main {
              color: var(--theme-text);
              border: 1px solid var(--border-secondary-color);
            }
          }

          &.--bottom {
            margin-bottom: 1em;
          }

          .message__main {
            margin: 1px;
            padding: 5px;
            border-radius: var(--default-radius);

            span {
              padding: 5px;
            }

            .message__time {
              text-align: end;
              color: var(--theme-secondary-text);
              font-size: var(--font-size-eleven);
            }
          }

          .message__avatar {
            margin: 5px;
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .chat__sender {
      position: fixed;
      bottom: 0;
      width: 100%;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: var(--theme-bg);
      border-top: 1px solid var(--border-secondary-color);

      textarea {
        padding: 10px;
        width: 70%;
        font-family: inherit;
        font-size: 1em;
        border: none;
        background: none;
      }
    }
  }
}
