.announcements {
  .announcements__main {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--theme-text);
    background-color: var(--theme-secondary-bg);
    box-shadow: 0 0 1em var(--shadow-color-settings);
    z-index: 101;

    &.--minimized {
      top: 30px;
      right: 30px;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      transition: transform 50ms;

      .announcements__header svg {
        animation: 1s shake infinite alternate;
      }
    }

    &.--full {
      padding: 0.5em 0;
      top: 15px;
      right: 0;
      margin: 1em;
      width: calc(100% - 2em);
      max-width: 600px;
      border-radius: var(--radius-twenty);
      transition: all 400ms;

      .announcements__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        h2 {
          font-weight: var(--font-weight-medium);
        }

        .close__icon {
          position: relative;
          width: 2em;
          height: 2em;

          &::before,
          &::after {
            position: absolute;
            left: 1em;
            content: "";
            height: 2em;
            width: 2px;
            background-color: var(--main-color);
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }

      .announcements__details {
        animation: fadein 500ms linear 1 forwards;

        .details__item {
          margin: 0.5em;
          padding: 0.5em;
        }
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: skewX(-15deg);
  }
  
  5% {
    transform: skewX(15deg);
  }
  
  10% {
    transform: skewX(-15deg);
  }
  
  15% {
    transform: skewX(15deg);
  }
  
  20% {
    transform: skewX(0deg);
  }
  
  100% {
    transform: skewX(0deg);
  }
}
