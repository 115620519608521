.tipList__element {
  .list__text {
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: var(--font-weight-semi-bold);
  }

  .list__options {
    display: flex;
    align-items: center;
    justify-content: center;

    .options__element {
      margin: 2vmin;
      padding: 8px;
      color: var(--theme-text);
      font-size: var(--font-size-fourteen);
      border: 1px solid var(--main-color);
      border-radius: var(--default-radius);

      &.--selected,
      &.--selected input {
        background-color: var(--main-color);
        color: white;
      }

      span,
      input {
        font-weight: var(--font-weight-heavy);
      }

      input {
        font-size: var(--font-size-fourteen);
        border: none;
      }
    }
  }
}
