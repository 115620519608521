.popup__main--variants {
  padding: 2vmin;
  text-align: center;

  .popup__title {
    margin: 5vmin;
    color: var(--theme-text);
    font-size: var(--font-size-twenty-four);
    font-weight: var(--font-weight-heavy);
  }

  .popup__list {
    margin: 0 12vmin;

    .list__element {
      margin: 2vmin;
      height: 12vmin;
      max-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--theme-text);
      font-size: var(--font-size-twelve);
      font-weight: bold;
      border-radius: var(--default-radius);
      border: 1px solid var(--main-color)
    }
  }
  
  .popup__button {
    margin: 5vmin;
    color: var(--theme-text);
    font-weight: var(--font-weight-heavy);
  }
}
