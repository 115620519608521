:root {
  --light-gold-color: rgba(210, 173, 86, 0.1);
  --gold-color: #d2ad56;
  --red-color: #d25656;
  --blue-color: #5688d2;
  --green-color: #a1d256;
  --pink-color: #d256a0;
  --border-color-desktop: #dbdbdb;
  --toast-bg: rgba(0, 0, 0, 0.8);
  --shadow-color-menu: rgba(0, 0, 0, 0.1);
  --shadow-color-settings: rgba(0, 0, 0, 0.19);
  --shadow-color-tiles: rgba(0, 0, 0, 0.07);
  --font-size-eight: 0.5em;
  --font-size-ten: 0.625em;
  --font-size-eleven: 0.6875em;
  --font-size-twelve: 0.75em;
  --font-size-thirteen: 0.8125em;
  --font-size-fourteen: 0.875em;
  --font-size-fiveteen: 0.9375em;
  --font-size-eighteen: 1.125em;
  --font-size-twenty: 1.25em;
  --font-size-twenty-two: 1.375em;
  --font-size-twenty-four: 1.5em;
  --font-size-twenty-six: 1.625em;
  --font-size-thirty: 1.875em;
  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-heavy: 800;
  --default-radius: 9px;
  --radius-twenty: 20px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.igorny {
  vertical-align: super;
  font-size: smaller;
}

.--muted {
  opacity: 0.3;
}

*:focus {
  outline: none;
}

input,
textarea {
  appearance: none;
}

ol {
  list-style-position: inside;
}
