.input__container {
  position: relative;
  margin: 1vmin 0;
  width: 100%;

  .input__icon--left {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .input__icon--right {
    position: absolute;
    margin: 0;
    top: 0;
    right: 0;
    z-index: 1;
  }

  input.input {
    position: relative;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    background: none;
    text-align: center;
    font-family: inherit;
    color: var(--theme-text);
    font-weight: var(--font-weight-semi-bold);
    border: 1px solid var(--border-secondary-color);
    border-radius: var(--default-radius);
    box-sizing: border-box;

    &.--warning {
      border-bottom: 1px solid var(--red-color);
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }

  span.input__warning {
    color: var(--red-color);
    font-size: var(--font-size-twelve);
  }
}
