button.button__text {
  margin: 6px auto;
  padding: 10px;
  width: 100%;
  color: white;
  font-family: inherit;
  font-size: var(--font-size-twelve);
  background-color: var(--main-color);
  border-radius: var(--default-radius);
  border: none;

  svg {
    width: 30px;
    height: 1em;
  }

  &.--normal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: var(--font-weight-heavy);
  }

  &.--border {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-text);
    font-weight: bold;
    background-color: var(--theme-secondary-bg);
    border: 1px solid var(--main-color);
  }

  &.--unfilled {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-text);
    background: none;
    font-size: var(--font-size-fourteen);
    font-weight: bold;
  }

  &.--reduced {
    padding: 5px 20px;
    width: min-content;
    text-align: center;
    font-weight: var(--font-weight-medium);
  }
}
