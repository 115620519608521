.popup__cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vmax;
  max-height: 400px;
  padding: 2em;
  padding-top: 8vh;
  color: var(--theme-bg);
  background-color: var(--theme-text);
  box-sizing: border-box;
  z-index: 1000;

  .popup__row {
    margin-bottom: 4vh;
    display: flex;
    justify-content: space-between;

    .popup__link {
      text-decoration: underline;
      color: var(--border-secondary-color);
      font-size: var(--font-size-fourteen);
    }
  }

  .popup__text {
    padding: .5em 0;
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-fourteen);

    .--enlarged {
      font-size: var(--font-size-twenty);
    }
  }

  .popup__button {
    padding: 2em;
  }
}
