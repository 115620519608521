.finalization {
  padding: 6vmax 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--theme-text);

  & > * {
    margin: 2vmin 4vmin;
  }

  .finalization__title {
    font-size: var(--font-size-thirty);
    font-weight: var(--font-weight-heavy);
    color: var(--theme-text);
  }

  .finalization__title--secondary {
    margin-top: 0;
    font-size: var(--font-size-fourteen);
    font-weight: var(--font-weight-semi-bold);
    color: var(--theme-secondary-text);
  }

  .finalization__tiles {
    margin: 5vmin 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .tiles__element {
      width: 45vmin;
      height: 45vmin;
      max-height: 250px;
      max-width: 250px;
      margin: 1.5vmin;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 1em var(--shadow-color-tiles);
      background-color: var(--theme-secondary-bg);
      border-radius: var(--radius-twenty);

      &.--selected {
        background-color: var(--main-color);

        .tiles__description {
          color: white;
        }
      }

      & > * {
        padding: 10px;
      }

      .tiles__title {
        padding: 6px;
        font-size: 42px;
        color: white;
      }

      .tiles__description {
        font-size: var(--font-size-fourteen);
        font-weight: var(--font-weight-semi-bold);
        color: var(--theme-text);
      }
    }
  }
}
