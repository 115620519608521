.popup--fetch {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--toast-bg);
  z-index: 1001;

  .popup__interior {
    margin: 1em 2em;
    padding: 2em;
    width: 90%;
    max-width: 600px;
    border-radius: var(--radius-twenty);
    background-color: var(--theme-secondary-bg);
    overflow: auto;
    box-sizing: border-box;

    .popup__error {
      text-align: center;
    }
  }

  .popup__button {
    margin-bottom: 1em;
  }
}
