.orders__list--current {
  .order {
    margin: 5vmin;
    padding: 5vmin;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--theme-text);
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    hr {
      margin: 1em 0;
      width: 100%;
      border: none;
      border-top: 1px solid var(--border-secondary-color);
    }

    .order__row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg,
      img {
        padding: 5px;
      }

      .order__text {
        font-size: var(--font-size-fourteen);
      }

      &.--menu {
        .order__group {
          width: 22vmin;
        }

        svg,
        img {
          width: 35px;
        }

        .order__text,
        .order__price {
          font-weight: bold;
        }
      }
    }

    .order__info {
      margin-top: 2em;

      p {
        margin-block-start: 0.3em;
      }
    }

    .order__text--medium {
      color: var(--main-color);
      font-size: 30px;
    }

    .order__text--large {
      color: var(--main-color);
      font-size: 42px;
    }

    p {
      font-size: var(--font-size-fiveteen);
      font-weight: bold;
    }

    .order__price--store {
      margin-block-start: 1em;
      margin-block-end: 1em;

      span {
        font-weight: var(--font-weight-heavy);
      }
    }

    .order__progress {
      margin: 0 5vmin;
      display: flex;
      align-items: center;
      justify-content: space-around;

      hr {
        width: 5vmin;
      }

      .bubble {
        width: 7vmin;
        height: 7vmin;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--main-color);
        border: 1px solid var(--border-secondary-color);
        border-radius: 50%;

        &.--highlighted {
          color: white;
          border-color: var(--main-color);
          background-color: var(--main-color);
        }
      }
    }

    .order__basket {
      margin: 0.4em;
      display: flex;
      border-radius: var(--default-radius);
      border: 1px solid var(--main-color);

      .basket__desc {
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .basket__col {
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &:nth-child(2) {
            padding: 0.3em;
            align-self: end;
          }

          .basket__title {
            text-align: left;

            span {
              color: var(--theme-text);
              font-weight: bold;

              &.--secondary {
                margin: 0 5px;
                color: var(--theme-secondary-text);
                font-weight: normal;
              }
            }
          }

          .basket__additional {
            .additional__title {
              font-size: var(--font-size-twelve);
              font-weight: var(--font-weight-semi-bold);

              &.--color {
                margin: 0 5px;
                color: var(--main-color);
              }

              &.--secondary {
                margin: 0 5px;
                color: var(--theme-secondary-text);
              }
            }
          }
        }
      }

      .basket__price {
        width: 25%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--theme-text);
        font-size: 1em;
        font-weight: var(--font-weight-heavy);
        border-left: 1px solid var(--main-color);

        sup {
          font-weight: normal;
        }
      }
    }
  }
}
