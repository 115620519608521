.shoppingbasket {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-secondary-color);
  border-radius: var(--default-radius);

  .basket__info, svg {
    padding: 5px;
  }

  .basket__info {
    font-weight: bold;
    font-size: var(--font-size-twelve);
    color: var(--theme-text);
  }
}