.banks {
  width: 100%;
  padding: 3em 1vmin 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 1vmin 0;
  }

  .banks__header {
    text-align: center;

    svg {
      width: 10vmin;
    }

    .header__title {
      color: var(--theme-text);
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      font-weight: var(--font-weight-heavy);
    }

    .header__text {
      color: var(--theme-secondary-text);
    }
  }

  .banks__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .list__error {
      text-align: center;
      color: var(--theme-secondary-text);
    }

    .bank img {
      margin: 1vmin;
      padding: 1vmin;
      width: 28vmin;
      max-width: 100px;
      background-color: white;
      border-radius: var(--radius-twenty);
      box-shadow: 0 0 1em var(--shadow-color-tiles);
    }
  }
}
