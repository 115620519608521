.popup__main--basketdetails {
  width: 80%;
  margin: 6% 10%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0.5em 0;
  }

  & > svg {
    width: 5em;
    height: 5em;
  }

  .popup__text,
  .additional__item {
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: var(--font-weight-semi-bold);

    &.--secondary {
      margin-bottom: 0;
      font-size: var(--font-size-twelve);
    }
  }

  .popup__title {
    color: var(--theme-text);
    font-size: var(--font-size-thirty);
    font-weight: var(--font-weight-heavy);

    &.--nomargin {
      margin: 0;
    }
  }

  .popup__additional.--food {
    text-align: left;

    .additional__item {
      span {
        margin: 0 0.3em;
      }

      span:first-child {
        color: var(--main-color);
      }
    }
  }

  .popup__additional.--order .additional__item {
    margin: 0.4em 0;

    &.--calc {
      font-size: var(--font-size-twenty);

      span {
        font-weight: var(--font-weight-heavy);
      }
    }
  }

  .popup__group {
    display: flex;
    align-items: center;

    & > * {
      margin: 0.5em;
    }

    .popup__title--secondary {
      color: var(--theme-text);
      font-size: var(--font-size-fiveteen);
      font-weight: bold;
    }
  }

  .popup__input {
    padding: 10px;
    width: 100%;
    min-height: 36px;
    height: 6em;
    max-height: 100px;
    text-align: center;
    font-size: 1em;
    font-family: inherit;
    font-weight: var(--font-weight-semi-bold);
    border: 1px solid var(--border-secondary-color);
    border-radius: var(--default-radius);
    background-color: var(--theme-secondary-bg);
    box-sizing: border-box;
    resize: vertical;

    &::placeholder {
      line-height: 48px;
    }
  }

  .popup__button {
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: bold;
  }

  .popup__buttons > * {
    margin: 0.4em 0;
  }
}
