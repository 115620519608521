.payment__restaurant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    color: var(--theme-text);
  }

  h1 {
    font-size: var(--font-size-thirty);
  }

  header {
    text-align: center;

    p {
      font-size: var(--font-size-fourteen);
      font-weight: var(--font-weight-semi-bold);
    }
  }

  .restaurant__main {
    margin: 5vmin;
    padding: 5vmin 10vmin;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    .restaurant__buttons {
      margin-top: 1.5em;
    }
  }
}
