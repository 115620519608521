.finalization__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .form__main {
    margin: 5vmin;
    padding: 5vmin 10vmin;
    display: flex;
    flex-direction: column;
    color: var(--theme-text);
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    & > * {
      margin: 1vmin 0;
    }
    
    .form__header {
      text-align: center;
    
      svg {
        width: 10vmin;
      }
    
      .header__title {
        color: var(--theme-text);
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        font-weight: var(--font-weight-heavy);
      }
    
      .header__text {
        color: var(--theme-secondary-text);
      }
    }

    .popup__error {
      color: var(--theme-text);
      text-align: center;
    }

    .form__company > * {
        margin: 1vmin 0;
    }

    hr {
      border: none;
      border-top: 1px solid var(--border-secondary-color);
    }

    .form__buttons {
      margin: 3vmin 0;

      hr {
        width: 70%;
      }
    }

    button.form__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      color: var(--theme-text);
      font-size: var(--font-size-twelve);

      & > * {
        padding: 5px;
      }
    }
  }

  .bouncing {
    animation: bouncing 2s ease;
  }
  
  @keyframes bouncing {
    30% { transform: scale(1.05); }
    40%, 60% { transform: rotate(-5deg) scale(1.05); }
    50% { transform: rotate(5deg) scale(1.05); }
    70% { transform: rotate(0deg) scale(1.05); }
    100% { transform: scale(1); }
  }
}
