.payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .payment__main {
    margin: 5vmin;
    padding: 5vmin 10vmin;
    width: 70vmin;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    color: var(--theme-text);
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    & > * {
      margin: 1vmin 0;
    }

    .payment__header {
      text-align: center;

      svg {
        width: 10vmin;
      }

      .header__title {
        color: var(--theme-text);
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        font-weight: var(--font-weight-heavy);
      }

      .header__text {
        color: var(--theme-secondary-text);
      }
    }

    .payment__list {
      .payment__element {
        margin: 0.5em 0;
        min-height: 40px;
        height: 12vmin;
        max-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border-secondary-color);
        border-radius: var(--default-radius);

        &.--none {
          border: none;

          & > * {
            padding: 0;
          }
        }

        &.--small img {
          width: 30px;
        }

        & > * {
          padding: 0 5px;
        }

        img {
          width: 60px;
        }

        span {
          font-weight: var(--font-weight-heavy);
        }
      }
    }
  }
}
