.food__list {
  padding: 0 4vmin;

  .list__main .food__header {
    margin: 2.5em 0;
    width: 100%;
  }

  .list__header {
    padding: 1.2em 1em;
    display: flex;
    position: sticky;
    align-items: center;
    justify-content: space-between;
    background-color: var(--theme-bg);
    font-size: var(--font-size-twelve);
    font-weight: var(--font-weight-semi-bold);
    color: var(--theme-text);

    &.--down {
      top: calc(11vmin + 2.6em);
      z-index: 11;
    }

    .list__group {
      display: flex;
      align-items: center;

      .list__sort {
        padding-left: 1em;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .food__list .list__main .list__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
