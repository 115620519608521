.technicalBreak {
  main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 500px;
    height: calc(var(--vh, 1vh)*100);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    & > * {
      margin: 1em 0;
    }

    svg #steam {
      #steam_top g {
        animation: float 1.5s infinite .2s;
      }

      #steam_bottom g {
        animation: float 1.5s infinite;
      }
    }
  }
}

@keyframes float {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
