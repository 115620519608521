.button__back {
  width: 2.6em;
  height: 2.6em;
  min-height: 34px;
  background-color: white;
  border-radius: var(--default-radius);
  border: 1px solid var(--border-secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    padding: 5px;
  }
}