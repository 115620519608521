$border: 1px solid var(--border-secondary-color);

.menu__submenu {
  .submenu__background {
    width: 100%;
    height: 67vmax;
    background-color: var(--toast-bg);

    .button__close {
      transform: translateY(60vmax);
    }
  }

  .submenu__main {
    width: 100%;
    height: 24vmax;
    max-height: 300px;
    padding: 2vmax;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--theme-bg);
    box-sizing: border-box;

    .submenu__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:first-child {
        padding: 1vmax 0;
        border-bottom: $border;
      }

      .submenu__group {
        display: flex;

        .submenu__title--secondary,
        svg {
          margin: 5px 0;
        }

        .submenu__title--secondary {
          padding: 0 5px;
          color: var(--theme-text);
          font-size: var(--font-size-twelve);
          font-weight: var(--font-weight-semi-bold);

          &:first-child {
            border-right: $border;
          }
        }
      }

      .submenu__element {
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        svg {
          padding: 5px;
        }

        .submenu__title {
          color: var(--theme-text);
          font-size: var(--font-size-fourteen);
          font-weight: bold;
        }
      }
    }
  }
}
