.popup__main--twobtn {
  padding: 5% 10%;
  text-align: center;

  .popup__title {
    color: var(--theme-text);
    font-weight: var(--font-weight-heavy);
  }

  .popup__description {
    color: var(--theme-secondary-text);
    margin-bottom: 10%;
  }

  .popup__buttons {
    display: flex;
    flex-direction: column;

    button {
      margin: 5px 0;
    }
  }
}
