.colors {
  display: flex;

  .colors__block {
    margin: 5px;
    width: 5vmax;
    max-width: 40px;
    height: 5vmax;
    max-height: 40px;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
    border-radius: var(--default-radius);
    opacity: 1;

    &.--dim {
      opacity: 0.5;
    }
  }

  #gold {
    background-color: var(--gold-color);
  }
  #red {
    background-color: var(--red-color);
  }
  #blue {
    background-color: var(--blue-color);
  }
  #green {
    background-color: var(--green-color);
  }
  #pink {
    background-color: var(--pink-color);
  }
}
