$border: 1px solid var(--main-color);
$borderWhite: 1px solid white;

.composeList__element {
  margin: 3vmin 2vmin;
  height: 12vmin;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--default-radius);
  border: $border;

  &.--filled {
    border: 1px solid var(--theme-secondary-bg);
    background-color: var(--main-color);

    .element__desc .element__title,
    .element__desc .element__icon,
    .element__price {
      color: white;
    }

    .element__desc {
      border-right: $borderWhite;

      .element__icon {
        border: $borderWhite;
      }
    }
  }

  .element__desc {
    width: 75%;
    height: 100%;
    padding: 0 2vmin;
    display: flex;
    align-items: center;
    border-right: $border;

    & > * {
      margin: 0 5px;
    }

    .element__icon {
      width: 6vmin;
      height: 6vmin;
      max-height: 25px;
      max-width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--theme-text);
      font-weight: bold;
      border-radius: var(--default-radius);
      border: $border;
    }

    .element__title {
      color: var(--theme-text);
      font-size: var(--font-size-twelve);
      font-weight: bold;
    }
  }

  .element__price {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-text);
    font-size: 1em;

    span {
      margin-right: 2px;
      font-weight: var(--font-weight-heavy);
    }
  }
}
