$color: white;

.barcode {
  position: absolute;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--theme-bg);

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  .barcode__title,
  .barcode__button {
    position: absolute;
    width: 100%;
    color: $color;
    text-align: center;
    z-index: 2;

    &.--normal {
      color: var(--theme-text);
    }
  }

  .barcode__title {
    top: 35%;
    font-size: 1.2em;
    font-weight: var(--font-weight-heavy);
  }

  .barcode__button {
    bottom: 5%;
    padding: 10px;
    font-size: var(--font-size-fourteen);
    font-weight: bold;
    box-sizing: border-box;

    button {
      color: $color;
    }
  }

  .barcode__frame {
    position: absolute;
    width: 100%;
    height: 44%;
    background-color: var(--toast-bg);
    z-index: 1;

    &.--top {
      top: 0;
    }

    &.--bottom {
      bottom: 0;
    }
  }
}
