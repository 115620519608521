$box-shadow: 0 -1em 1em var(--shadow-color-menu);

.search__panel {
  width: 100%;

  .search__arrow {
    position: absolute;
    width: 12vmin;
    max-width: 80px;
    height: 20px;
    top: -20px;
    right: 30px;
    cursor: pointer;
    background-color: var(--theme-secondary-bg);
    border-top-left-radius: var(--default-radius);
    border-top-right-radius: var(--default-radius);
    box-shadow: $box-shadow;

    .arrow__down {
      display: block;
      margin: 1vmax auto;
      width: 8px;
      height: 8px;
      border-top: 2px solid var(--theme-text);
      border-left: 2px solid var(--theme-text);
      transform: rotate(-135deg);
    }
  }

  .search__main {
    height: 17vmax;
    max-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-secondary-bg);
    box-shadow: $box-shadow;

    .search__input {
      width: 90%;
      height: 50%;
      text-align: center;
      font-size: 1em;
      border: 1px solid var(--border-secondary-color);
      border-radius: var(--default-radius);
      background-color: var(--theme-secondary-bg);
      font-weight: var(--font-weight-semi-bold);
      color: var(--theme-text);
    }
  }
}

.search__panel--minimized {
  position: absolute;
  width: 30vmin;
  max-width: 200px;
  height: 34px;
  top: -34px;
  right: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: var(--theme-secondary-bg);
  border-top-left-radius: var(--default-radius);
  border-top-right-radius: var(--default-radius);
  box-shadow: $box-shadow;
  box-sizing: border-box;

  span {
    padding: 10px;
    overflow: hidden;
    color: var(--theme-text);

    &:first-child {
      width: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
