$color: white;

.scan {
  position: absolute;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--theme-bg);

  section {
    section {
      padding: 0 !important;
      position: absolute !important;
      height: 100%;

      div {
        display: none !important;
      }
    }
  }

  .scan__title,
  .scan__button, .scan__support {
    position: absolute;
    width: 100%;
    color: $color;
    text-align: center;
    z-index: 2;

    &.--normal {
      color: var(--theme-text);
    }
  }

  .scan__support {
    top: 10%;
    color: $color;
  }

  .scan__title {
    top: 50%;
    transform: translateY(-160px);
    font-size: 1.1em;
    font-weight: var(--font-weight-heavy);
  }

  .scan__button {
    bottom: 5%;
    padding: 10px;
    font-size: var(--font-size-fourteen);
    font-weight: bold;
    box-sizing: border-box;

    button {
      color: $color;
    }
  }

  .frame__cross {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: $color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      width: 2px;
      height: 50px;
    }

    &::after {
      width: 50px;
      height: 2px;
    }
  }

  .scan__frame {
    position: absolute;
    background-color: var(--toast-bg);
    z-index: 1;

    &.--top, &.--bottom {
      width: 100%;
      height: calc(50% - 120px);
    }

    &.--left, &.--right {
      width: calc(50% - 120px);
      height: 240px;
      top: calc(50% - 120px);
    }

    &.--top {
      top: 0;
    }

    &.--left {
      left: 0;
    }

    &.--right {
      right: 0;
    }

    &.--bottom {
      bottom: 0;
    }
  }
}
