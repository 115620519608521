.blik {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .blik__main {
    margin: 5vmin;
    padding: 5vmin 10vmin;
    width: 70vmin;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: var(--theme-text);
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    header {
      h2 {
        margin-block-start: 0.2em;
        margin-block-end: 0.2em;
        font-weight: normal;
      }

      img {
        width: 90px;
      }
    }

    .blik__phone,
    .blik__price,
    .blik__form {
      margin: 2em 0;
    }

    .blik__price {
      font-size: var(--font-size-twenty-four);

      .--heavy {
        font-weight: var(--font-weight-heavy);
      }
    }

    .blik__title,
    .blik__error {
      margin-bottom: 0.5em;
      font-size: var(--font-size-twenty);
      font-weight: bold;
    }

    .blik__error {
      color: var(--red-color);
    }

    .blik__text {
      font-size: var(--font-size-eight);
    }
  }
}
