.basket {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 5vmin;
  }

  .basket__list {
    padding: 1em 0;
    width: 90%;
    max-width: 900px;
    background-color: var(--theme-secondary-bg);
    box-shadow: 0 0 1em var(--shadow-color-tiles);
    border-radius: var(--radius-twenty);
  
    .list__title,
    .list__price {
      padding: .6em;
    }
  
    .list__title {
      text-align: center;
      color: var(--theme-text);
      font-size: var(--font-size-twenty-four);
      font-weight: var(--font-weight-heavy);
    }
  
    .list__main .list__element {
      margin: .6em 1em;
      display: flex;
      border-radius: var(--default-radius);
      border: 1px solid var(--main-color);
    
      &.--none {
        display: none;
      }
    
      .element__description {
        padding: 10px;
        width: 75%;
        display: flex;
        justify-content: space-between;
    
        .element__col {
          &:nth-child(2) {
            padding: 0 5%;
            align-self: flex-end;
          }
    
          .element__title {
            color: var(--theme-text);
            font-size: 1em;
            font-weight: bold;
    
            &.--secondary {
              margin: 0 5px;
              font-size: var(--font-size-eleven);
            }
          }
    
          .additional__list .additional__element {
            .element__title {
              font-size: var(--font-size-twelve);
              font-weight: var(--font-weight-semi-bold);
    
              &.--color {
                margin: 0 5px;
                color: var(--main-color);
              }
    
              &.--secondary {
                font-size: var(--font-size-ten);
              }
            }
          }
        }
      }
    
      .element__price {
        width: 25%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--theme-text);
        font-size: 1em;
        font-weight: var(--font-weight-heavy);
        border-left: 1px solid var(--main-color);
    
        sup {
          font-weight: normal;
        }
      }
    }
  }  

  .basket__button--reset {
    margin-top: 10vmin;
  }

  .basket__buttons {
    width: 70%;
    max-width: 500px;
    margin: 6vmin 15vmin;
  }
}
