.popup--fb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--toast-bg);
  z-index: 999;

  .popup__arrow {
    position: fixed;
    top: 0;
    right: 10px;
  }

  .popup__interior {
    margin: 2% 5%;
    width: 90%;
    max-width: 600px;
    border-radius: var(--radius-twenty);
    background-color: var(--theme-secondary-bg);
    overflow: auto;
    
    .popup__main {
      padding: 5% 0;
      text-align: center;
    
      .popup__button {
        padding: 1em 1.5em 0;
      }
    
      .popup__title {
        color: var(--theme-text);
        font-weight: var(--font-weight-heavy);
      }
    
      .popup__description {
        padding: .5em 1em;
        color: var(--theme-secondary-text);
      }
    }
    
  }
}
