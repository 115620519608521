.button__price {
  width: fit-content;
  min-height: 34px;
  height: 2.6em;
  display: flex;
  align-items: center;
  background: none;
  font-family: inherit;
  border-radius: var(--default-radius);
  border: 1px solid var(--main-color);

  &.--white {
    border: 1px solid white;

    .button__description {
      color: white;
    }
  }

  .button__description {
    padding: 10px;
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: var(--font-weight-heavy);
    white-space: nowrap;

    sup {
      padding: 1px;
      font-weight: normal;
    }
  }
}
