.main--mobile {
  width: 100%;
  padding-bottom: 10vh;
  background-color: var(--theme-bg);

  & > *:first-child {
    box-sizing: border-box;
    min-height: calc(var(--vh, 1vh) * 90);
    transition: min-height 200ms ease;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .main--mobile {
    padding-bottom: 10vmax;
  }
}
