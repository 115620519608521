.food {
  width: 100%;

  .food__navigation {
    display: flex;
    position: sticky;
    z-index: 10;
    top: 0;
    padding: 7vmin 4vmin 4vmin 4vmin;
    background-color: var(--theme-bg);

    .navigation__list {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
    }

    & > button,
    .navigation__list > .navigation__item {
      margin-right: 5px;
    }
  }

  .food__header {
    margin: 1vmin 4vmin;
    display: flex;
    align-items: center;

    svg {
      width: 2em;
      height: 2em;
    }

    & > div {
      margin: 0 5px;
    }

    & > button {
      margin-right: 2vmax;
    }

    .food__title {
      color: var(--theme-text);
      font-size: var(--font-size-thirty);
      font-weight: var(--font-weight-heavy);
      white-space: nowrap;
    }

    .food__line {
      height: 2px;
      width: 16px;
      background-color: var(--main-color);
    }

    .food__greeting {
      color: var(--theme-text);
      font-size: var(--font-size-twelve);
      font-weight: var(--font-weight-semi-bold);
    }
  }
}
