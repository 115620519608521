.categories {
  margin: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/backgrounds/categories.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;

  .categories__titles {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    & > div {
      padding: 5px;
    }

    .categories__title {
      font-size: var(--font-size-thirty);
      font-weight: var(--font-weight-heavy);
      border-bottom: 1px solid var(--main-color);

      &--secondary {
        font-size: var(--font-size-twelve);
        font-weight: var(--font-weight-semi-bold);
      }
    }
  }

  .categories__tiles {
    padding: 2vmin;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.categories--desktop {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 550px) and (orientation: landscape) {
  .categories .error {
    color: white;
  }
}