.orders {
  .orders__error {
    padding: 5vmin;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    color: var(--theme-text);
    transform: translate(-50%, -50%);
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);
  }

  .orders__menu {
    height: 15vmax;
    max-height: 110px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    background-color: var(--theme-secondary-bg);
    box-shadow: 0 0.5em 0.5em var(--shadow-color-menu);
    z-index: 2;

    hr {
      width: 1px;
      height: 80%;
      border: none;
      border-right: 1px solid var(--border-secondary-color);
    }

    .menu__item {
      margin: 1vmin;
      padding: 2vmin 1vmin;
      width: 24%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid var(--theme-secondary-bg);
      border-width: 3px 0;

      &:first-child {
        background-color: var(--main-color);
        border-radius: var(--default-radius);

        .item__desc {
          color: white;
        }
      }

      &:nth-child(3) svg g > * {
        fill: var(--main-color);
      }

      &.--selected {
        border-bottom: 3px solid var(--main-color);
      }

      svg {
        width: 2em;
        height: 2em;
        padding: 5px;
      }

      .item__desc {
        text-align: center;
        color: var(--theme-text);
        font-size: var(--font-size-twelve);
        font-weight: var(--font-weight-semi-bold);
      }
    }
  }
}
