.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--theme-text);

  .home__main {
    margin: auto 18vw;

    .home__title {
      margin-top: 2em;
      margin-bottom: 3px;
      font-weight: var(--font-weight-heavy);
      color: var(--theme-text);
    }

    .home__title--secondary {
      margin: 0.5em 0;
      font-size: var(--font-size-twelve);
      color: var(--theme-secondary-text);
    }

    .home__tiles {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
      height: auto;

      .tiles__element {
        width: 28vmin;
        height: 28vmin;
        max-width: 180px;
        max-height: 180px;
        margin: 1vmin;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 1em var(--shadow-color-tiles);
        background-color: var(--theme-secondary-bg);
        border-radius: var(--radius-twenty);
        border: none;

        svg {
          padding-top: 10px;
          height: 40px;
          min-width: 30px;
        }

        .tiles__description {
          font-size: var(--font-size-twelve);
          font-weight: bold;
          color: var(--color-text);
          padding: 10px;
        }
      }
    }

    @media screen and (max-height: 45em) {
      .home__title {
        margin-top: 1em;
      }
    }
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .home {
    min-height: 80vmax !important;
  }
}
