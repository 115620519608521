.popup__main--tip {
  padding: 10%;
  text-align: center;

  .popup__title {
    color: var(--theme-text);
    font-size: var(--font-size-thirty);
    font-weight: var(--font-weight-heavy);
  }

  .popup__text {
    color: var(--theme-text);
    font-size: var(--font-size-fourteen);
    font-weight: var(--font-weight-semi-bold);
  }
  
  .popup__list {
    margin: 10% 0;
  }
}
