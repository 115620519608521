.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo__text {
    font-size: 2em;

    span.--medium {
      font-family: 'Poppins-Medium', 'Poppins';
      font-weight: var(--font-weight-medium);
    }

    span.--light {
      font-family: 'Poppins-Light', 'Poppins';
      font-weight: var(--font-weight-light);
    }
  }
}