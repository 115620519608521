.button__close {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.--whitebg .button__description {
    color: var(--theme-text);
  }

  svg, .button__description {
    padding: 5px;
  }

  .button__description {
    color: white;
    font-weight: bold;
    font-size: var(--font-size-fourteen);
  }
}
