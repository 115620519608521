textarea.form__comments {
  width: 100%;
  min-height: 36px;
  max-height: 200px;
  padding: 10px;
  font-size: 1em;
  background: none;
  text-align: center;
  font-family: inherit;
  color: var(--theme-text);
  font-weight: var(--font-weight-semi-bold);
  border: 1px solid var(--border-secondary-color);
  border-radius: var(--default-radius);
  box-sizing: border-box;
  resize: vertical;
}
