$border: 1px solid var(--border-color);
$box-shadow: 0 1em 1em var(--shadow-color-settings);

.settings {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  .settings__panel {
    width: 100%;
    height: 0;
    max-height: 0;
    border-bottom: $border;
    background-color: var(--theme-secondary-bg);
    transition: all 500ms ease;

    .settings__message {
      color: var(--theme-text);
      font-size: var(--font-size-twelve);
      font-weight: var(--font-weight-semi-bold);
      position: absolute;
      bottom: 16px;
      right: 30px;
    }
  }

  .settings__arrow {
    position: absolute;
    width: 12vmin;
    max-width: 80px;
    height: 20px;
    bottom: -20px;
    right: 30px;
    background-color: var(--theme-secondary-bg);
    border-right: $border;
    border-left: $border;
    border-bottom: $border;
    border-bottom-left-radius: var(--default-radius);
    border-bottom-right-radius: var(--default-radius);
    transition: top 500ms ease 0s;

    .arrow__up,
    .arrow__down {
      cursor: pointer;
      display: block;
      width: 8px;
      height: 8px;
      margin: 2px auto;
      border-top: 2px solid var(--theme-text);
      border-left: 2px solid var(--theme-text);
    }

    .arrow__up {
      transform: rotate(45deg);
    }

    .arrow__down {
      transform: rotate(-135deg);
    }
  }

  &.--wide {
    .settings__panel {
      height: 24vmax;
      max-height: 160px;
      border: none;
      box-shadow: $box-shadow;
    }

    .settings__arrow {
      border: none;
      box-shadow: $box-shadow;
    }
  }
}
