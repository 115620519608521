.popup__main--service {
  color: var(--theme-text);
  text-align: center;
  padding: 5% 10%;

  .service__title {
    font-weight: var(--font-weight-heavy);
    margin-bottom: 0.5em;
  }

  .popup__description {
    color: var(--theme-secondary-text);
  }

  .popup__inputs {
    margin: 1em 0;

    span.input__warning {
      color: var(--red-color);
      font-size: var(--font-size-twelve);
    }
  }

  .service__tiles {
    margin: 1em 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .service__tile {
      width: 22vmin;
      height: 22vmin;
      margin: 1vmin;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 1em var(--shadow-color-tiles);
      background-color: var(--theme-secondary-bg);
      border-radius: var(--radius-twenty);
      border: none;

      svg {
        padding: 0.4em;
      }
    }
  }

  .service__desc {
    font-size: var(--font-size-eleven);
    font-weight: bold;
  }

  .service__chats {
    margin: 1em 0;
    display: flex;
    justify-content: center;
    overflow-x: auto;

    .chats__tile {
      margin: 1vmin;
      padding: 10px;
      min-width: 40px;
      flex-shrink: 0;
      box-shadow: 0 0 1em var(--shadow-color-tiles);
      background-color: var(--theme-secondary-bg);
      border-radius: var(--radius-twenty);

      span {
        font-size: var(--font-size-eleven);
      }
    }
  }

  .button__desc {
    font-size: var(--font-size-eleven);
    color: var(--theme-secondary-text);
  }

  .service__button {
    display: flex;
    justify-content: center;
    align-items: center;

    &.--basket {
      margin: 0.8em 0;

      .button__basket {
        display: flex;
        justify-content: center;
        font-weight: var(--font-weight-heavy);
        box-sizing: border-box;

        &.--left {
          width: 60%;
          padding: 10px;
          font-size: var(--font-size-twelve);
          border: solid var(--border-secondary-color);
          border-top-left-radius: var(--default-radius);
          border-bottom-left-radius: var(--default-radius);
          border-width: 1px 0 1px 1px;

          & > * {
            padding: 0 4px;
          }
        }

        &.--right {
          width: 40%;
          padding: 11px;
          background-color: var(--main-color);
          border-top-right-radius: var(--default-radius);
          border-bottom-right-radius: var(--default-radius);
          color: white;
        }
      }
    }

    &.--chat > * {
      margin: 5px;
      font-size: var(--font-size-fourteen);
      font-weight: bold;
    }
  }
}
