.date {
  .popup__calendar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--toast-bg);
    z-index: 999;

    .popup__interior {
      width: 100%;
      background-color: var(--theme-secondary-bg);
      border-top-left-radius: var(--radius-twenty);
      border-top-right-radius: var(--radius-twenty);

      .popup__main {
        padding: 2em 2em 3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .react-calendar {
          max-width: 600px;
          min-height: 258px;

          .react-calendar__navigation {
            width: 100%;
            display: flex;
          }

          .react-calendar__month-view__weekdays__weekday {
            padding: 0.6em;
            color: var(--theme-text);
            text-align: center;

            abbr {
              text-decoration: none;
              font-weight: var(--font-weight-semi-bold);
            }
          }

          button {
            margin: 0;
            padding: 0.6em;
            background: none;
            color: var(--theme-text);
            font-family: inherit;
            border: none;

            &.react-calendar__navigation__arrow {
              padding: 0.6em 1.6em;

              &.react-calendar__navigation__prev2-button,
              &.react-calendar__navigation__next2-button {
                display: none;
              }
            }

            &.react-calendar__tile--active {
              color: var(--theme-secondary-bg);
              border-radius: var(--default-radius);
              background-color: var(--main-color);
            }

            &:disabled {
              opacity: 0.5;
            }
          }
        }

        .popup__button {
          margin-top: 2em;
          padding: 0.75em;
          color: var(--theme-text);
          font-size: 0.875em;
          text-align: center;
          border: 1px solid var(--theme-text);
          border-radius: var(--default-radius);
        }
      }
    }
  }

  .date__container {
    position: relative;
    margin: 1vmin 0;
    width: 100%;

    .date__icon {
      position: absolute;
      top: 10px;

      &.--left {
        left: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &.--right {
        right: 10px;
      }
    }

    .date__placeholder {
      position: absolute;
      top: 12px;
      left: 20%;
      width: 60%;
      color: grey;
      text-align: center;
      background-color: var(--theme-secondary-bg);
      font-weight: var(--font-weight-semi-bold);
    }

    .date__input {
      position: relative;
      width: 100%;
      min-height: 40px;
      padding: 10px;
      font-size: 1em;
      background: none;
      text-align: center;
      color: var(--theme-text);
      font-weight: var(--font-weight-semi-bold);
      border: 1px solid var(--border-secondary-color);
      border-radius: var(--default-radius);
      box-sizing: border-box;
      display: block;

      &.--warning {
        border-bottom: 1px solid var(--red-color);
      }
    }

    span.date__warning {
      color: var(--red-color);
      font-size: var(--font-size-twelve);
    }
  }
}
