.friend__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form__main {
    margin: 5vmin;
    padding: 5vmin 10vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--theme-secondary-bg);
    border-radius: var(--radius-twenty);
    box-shadow: 0 0 1em var(--shadow-color-tiles);

    .form__title {
      color: var(--theme-text);
      font-size: var(--font-size-twenty-four);
      font-weight: var(--font-weight-heavy);
    }

    .form__inputs {
      width: 100%;
      margin-bottom: 10%;
    }

    .form__text {
      color: var(--theme-secondary-text);
      font-size: var(--font-size-twelve);
    }
  }
}
