.menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;

  .menu__main {
    height: 10vmax;
    min-height: 60px;
    max-height: 75px;
    padding: 0 2vmax;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--theme-secondary-bg);
    box-shadow: 0 -0.5em 0.5em var(--shadow-color-menu);
    box-sizing: border-box;

    .menu__switch {
      width: 40px;
      text-align: center;
    }

    .menu__description {
      text-align: center;
      font-size: var(--font-size-twelve);
      font-weight: var(--font-weight-semi-bold);
      color: var(--theme-text);
    }
  }
}
