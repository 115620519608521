$size: 22px;

.checkbox__container {
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
  
  .checkbox__icon {
    margin: 2px .5em 0;
  }

  label {
    padding-top: 5px;
    padding-left: 30px;
    min-height: $size;
    position: relative;
    cursor: pointer;
    user-select: none;
    color: var(--theme-secondary-text);
    font-size: var(--font-size-eleven);
    text-align: left;

    input {
      display: none;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: $size;
      width: $size;
      border: 1px solid var(--border-secondary-color);
      border-radius: var(--default-radius);

      &.--warning {
        border: 1px solid var(--red-color);
      }

      &:after {
        content: "";
        position: absolute;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid var(--main-color);
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        display: none;
      }
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
}
