.popup__main--findCompany {
  padding: 2vmin;
  text-align: center;

  .popup__title {
    margin: 5vmin;
    color: var(--theme-text);
    font-size: var(--font-size-twenty-four);
    font-weight: var(--font-weight-heavy);
  }

  .popup__list {
    margin: 0 12vmin;

    .list__element {
      margin: 2vmin;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: var(--default-radius);
      border: 1px solid var(--main-color);

      .element__title {
        padding: 10px;
        color: var(--theme-text);
        font-size: var(--font-size-twelve);
        font-weight: bold;

        &.--secondary {
          padding-top: 0;
          font-weight: normal;
        }
      }
    }
  }
}
