.--light {
  --theme-bg: #fff;
  --theme-secondary-bg: #fff;
  --theme-text: #15171a;
  --theme-secondary-text: #4f4f4f;
  --border-color: #e8e8e8;
  --border-secondary-color: #e8e8e8;
}

.--dark {
  --theme-bg:#15171a;
  --theme-secondary-bg:#1e2023;
  --theme-text: #fff;
  --theme-secondary-text: #7b7e84;
  --border-color: none;
  --border-secondary-color: #7B7E84;
}

.--gold {
  --main-color: var(--gold-color);
}

.--red {
  --main-color: var(--red-color);
}

.--blue {
  --main-color: var(--blue-color);
}

.--green {
  --main-color: var(--green-color);
}

.--pink {
  --main-color: var(--pink-color);
}