$twelve: var(--font-size-twelve);

.list__element--wide {
  width: 92vmin;
  height: 92vmin;
  max-height: 400px;
  max-width: 400px;
  margin: 4vmin 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--theme-secondary-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: var(--radius-twenty);

  &.--inactive {
    opacity: 0.5;
  }

  &.--white {
    .element__top .element__col .element__title,
    .element__top .element__col .element__price,
    .element__bottom .element__col:first-child button div {
      color: #fff;
    }
  }

  &.--black {
    .element__top .element__col .element__title,
    .element__bottom .element__col:first-child button div {
      color: #000;
    }
  }

  .element__top,
  .element__bottom {
    display: flex;
    justify-content: space-between;
  }

  .element__col {
    display: flex;
    flex-direction: column;

    .element__title {
      margin-bottom: 4em;
      font-size: var(--font-size-twenty);
      font-weight: var(--font-weight-heavy);
      color: var(--theme-text);
    }

    .element__desc {
      color: var(--theme-secondary-text);
      font-size: var(--font-size-thirteen);
      display: flex;

      div {
        width: 35vmax;
        max-width: 200px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .element__price {
      align-self: center;
      margin-bottom: 0.6rem;
      color: var(--theme-text);
      text-align: center;
      width: max-content;

      div:first-child {
        font-size: var(--font-size-eighteen);
        font-weight: var(--font-weight-heavy);
      }

      div:last-child {
        font-size: $twelve;
      }
    }

    .element__group {
      display: flex;

      .element__title {
        margin-left: 0.5em;
        margin-bottom: 0;
        font-size: $twelve;
        font-weight: normal;
      }
    }

    .element__icon svg {
      margin-bottom: 1.5em;
      width: 10vmax;
      height: 10vmax;
      max-width: 80px;
      max-height: 80px;
    }
  }

  .element__top {
    padding: 1.5em 0 0.5em;
    min-height: 30%;

    &.--filled {
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        border-top-left-radius: var(--radius-twenty);
        border-top-right-radius: var(--radius-twenty);
        background-color: var(--theme-text);
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        z-index: -1;
      }

      .element__col {
        &:first-child {
          width: 60%;
        }
        
        .element__title,
        .element__group .element__desc {
          margin-bottom: 0.6rem;
        }

        .element__desc {
          color: #fff;

          div {
            margin-right: 0.4em;
            width: 60%;
            max-width: 220px;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .element__col {
      padding: 0 1em;
    }
  }

  .element__bottom {
    padding: 0.5em 1.5em 1.5em;
    align-items: flex-end;

    .element__col {
      align-items: center;
    }
  }
}

@media screen and (min-width: 500px) {
  .list__element--wide {
    margin: 1vmin;
  }
}
